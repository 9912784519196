import React, { FC } from 'react';
import Heading from '@components/views/Heading';
import Chip from '@components/views/Chip';
import Button from '../../../Button';
import DefaultSlidePattern from './DefaultSlidePattern';
import ContainImage from '@components/views/ContainImage';
import { SlideProps } from '../../types';

const DefaultSlide: FC<SlideProps> = (props) => {
  const {
    buttons,
    heading,
    description,
    chip,
    backgroundColor,
    image,
    backgroundImage,
  } = props;
  return (
    <div className="h-full relative grid">
      <div className="h-full lg:absolute col-span-full md:col-span-6 xl:col-span-5 flex flex-col pt-20 xl:pt-48 relative z-30 w-full">
        <div className="flex flex-col justify-between h-full lg:w-[82%]">
          <div className="pb-32 lg:pb-112">
            {heading && (
              <div className="text-2xl lg:text-6xl xl:text-9xl font-extrabold lg:max-w-[85rem]">
                <Heading {...heading} />
              </div>
            )}
            {description && (
              <p
                className="text-sm md:text-base text-neutral-9 pt-16 pr-32 lg:pr-0 line-clamp-3 md:line-clamp-5 lg:w-1/2 xxl:w-2/3 xl:pl-2"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}

            {buttons && buttons?.length > 0 && (
              <div className="mt-24 xl:pl-2 hidden md:block">
                {buttons.map(
                  ({ button: { title, url, variant } }, index) =>
                    url && (
                      <div key={index} className="mr-16 mt-16">
                        <Button href={url} variant={variant || 'PRIMARY'}>
                          {title}
                        </Button>
                      </div>
                    ),
                )}
              </div>
            )}
          </div>
          <div className="hidden md:block">
            {chip?.title && <Chip {...chip}>{chip.title}</Chip>}
          </div>
        </div>
      </div>
      <div className="grid w-full col-span-full relative md:col-span-6 xl:col-span-7 z-20">
        {image && (
          <div className="w-[70%] lg:w-[50%] self-end justify-self-end absolute lg:h-full z-10 flex items-end">
            <ContainImage src={image} />
          </div>
        )}

        <div className="flex items-end self-end static w-full h-full lg:mt-[18rem]">
          <DefaultSlidePattern
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
          />
        </div>
        <div className="absolute bottom-6 left-6 z-10">
          {buttons && buttons?.length > 0 && (
            <div className="flex flex-wrap gap-6 md:hidden">
              {buttons.map(
                ({ button: { title, url, variant } }, index) =>
                  url && (
                    <div key={index}>
                      <Button
                        href={url}
                        variant={variant || 'PRIMARY'}
                        size="small"
                      >
                        {title}
                      </Button>
                    </div>
                  ),
              )}
            </div>
          )}
          <div className="mt-12 md:hidden">
            {chip?.title && <Chip {...chip}>{chip.title}</Chip>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultSlide;
