import React from 'react';
import MainSlider from '../MainSlider';
import SecondarySlider from '../SecondarySlider';
import TertiarySlider from '@components/containers/TertiarySlider';

export interface HeroCarouselProps {
  slides: Array<any>;
  type: 'PRIMARY' | 'SECONDARY' | 'TERTIARY';
}

const HeroCarousel = ({ type, slides }: HeroCarouselProps) => {
  if (type === 'SECONDARY') return <SecondarySlider slides={slides} />;
  if (type === 'TERTIARY') return <TertiarySlider slides={slides} />;
  return <MainSlider slides={slides} />;
};

export default HeroCarousel;
