import HeroBanner, { HeroBannerProps } from '@components/views/HeroBanner';
import Slider from '@components/views/Slider';
import { SplideOptions } from '@components/views/Slider/types';
import { useAppContext } from 'src/context/app.context';
import { usePostTypeCollection } from 'src/services/api/queries/hooks/usePostTypeCollection';
import { getFormattedSeoYoastBreadcrumbs } from 'src/utils/getFormattedSeoYoastBreadcrumbs';

interface SecondarySliderProps {
  slides: Array<HeroBannerProps>;
}

const config: SplideOptions = {};

const SecondarySlider = ({ slides }: SecondarySliderProps) => {
  const { permalink } = useAppContext();

  const pageFromSlugData = usePostTypeCollection(
    'product',
    {
      permalink,
    },
    {
      enabled: !!permalink,
    },
  ).data?.data;

  if (!pageFromSlugData) return null;

  const [pageData] = pageFromSlugData;

  const breadcrumbs =
    pageData && getFormattedSeoYoastBreadcrumbs(pageData.yoast_head_json);

  const decorationColor = pageData && pageData.meta_data.colors.main;

  return (
    <Slider
      SlideComponent={(props) => (
        <HeroBanner
          {...props}
          breadcrumbs={breadcrumbs}
          decoration_color={decorationColor}
        />
      )}
      slides={slides}
      config={config}
    />
  );
};

export default SecondarySlider;
