import { Splide, SplideSlide } from '@splidejs/react-splide';
import styled, { css } from 'styled-components';
import '@splidejs/splide/dist/css/splide.min.css';
import Container, { ContainerProps } from '@components/containers/Container';
import { SplideOptions } from './types';
import PauseIcon from '../../../assets/images/icons/pause.svg';
import { useRef } from 'react';
import { mediaQueries } from '@styles/theme';
import SliderPatternSVG from '../../../assets/images/slider-pattern.svg';
import { IPostACF } from 'src/services/api/types/IPosts';
import omit from 'lodash/omit';

interface SliderProps<T> {
  config?: SplideOptions;
  SlideComponent: React.FC<T>;
  slides: Array<T>;
  withContainer?: boolean;
  hasPaddingLeft?: ContainerProps['hasPaddingLeft'];
  isTertiary?: boolean;
}

const SplideOveriter = styled(Container)<{
  isMain?: boolean;
  isSecondary?: boolean;
  isTertiary?: boolean;
}>`
  .splide__pagination__page {
    border: 1px solid ${({ theme }) => theme.colors.neutral[9]};
    background: transparent;
    width: 8px;
    height: 8px;
    margin-right: 6px;
    margin-left: 6px;

    &.is-active {
      background: ${({ theme }) => theme.colors.neutral[9]};
      transform: scale(1);
    }
  }

  ${({ isMain }) =>
    isMain &&
    css`
      .splide__pagination {
        justify-content: start;

        ${mediaQueries.lessThan('md')`
          left: -2rem;
          bottom: clamp(15rem, 42vw, 29rem);
        `};

        ${mediaQueries.greaterThan('lg')`
          --tileWidth: calc((100vw - 4rem) / 6);
          left: 29rem;
          bottom: 11rem;
        `};
      }
    `}

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      .splide__pagination {
        justify-content: start;

        ${mediaQueries.greaterThan('md')`
          justify-content: center;
        `};

        ${mediaQueries.greaterThan('lg')`
          justify-content: center;
          bottom: 10.8rem;
          left: 25%;
        `};
      }
    `}

  ${({ isTertiary }) =>
    isTertiary &&
    css`
      .splide__pagination__page {
        border: 1px solid ${({ theme }) => theme.colors.neutral[0]};
        opacity: 1;

        &.is-active {
          background: ${({ theme }) => theme.colors.neutral[0]};
          transform: scale(1);
        }
      }

      .splide__pagination {
        justify-content: start;
        display: inline-flex;
        max-width: var(--container-width);
        margin: 0 auto;
        padding-left: 0;
        bottom: 3.2rem;

        > li {
          display: flex;
          align-items: center;
        }

        ${mediaQueries.lessThan('xl')`
          bottom: 1.5rem;
        `};

        ${mediaQueries.lessThan('md')`
          left: -0.8rem
        `};

        ${mediaQueries.lessThan('sm')`
          bottom: 0.6rem;
        `};
      }

      #pause-icon path {
        fill: ${({ theme }) => theme.colors.neutral[0]};
      }
    `}
`;

const PauseElement = styled.div`
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;
  margin-left: 1.8rem;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
`;

const convertSlidePostToSlideProps = (slide: IPostACF) => {
  const isMeta = !!slide.meta_data;
  return {
    ...(isMeta && { ...slide.meta_data }),
    heading: { title: slide.post_title, level: 'h2' },
    description: slide.excerpt,
    image: slide.thumbnail,
  };
};

const defaultConfig: SplideOptions = {
  perPage: 1,
  arrows: false,
  gap: 66,
  autoplay: true,
  pauseOnHover: true,
  keyboard: true,
  type: 'loop',
  speed: 700,
  pauseOnFocus: false,
};

const Slider = <T,>({
  config,
  SlideComponent,
  slides,
  withContainer,
  isTertiary,
}: SliderProps<T>) => {
  const pauseIconRef = useRef<HTMLDivElement>(null);

  const hasMultipleSlides = slides.length > 1;
  const isMainSlider = withContainer;
  const isSecondarySlider = Boolean(!withContainer);

  let customConfig: SplideOptions = config;
  if (!hasMultipleSlides) {
    customConfig = {
      ...config,
      autoplay: false,
      drag: false,
      pagination: false,
    };
  }
  return (
    <SplideOveriter
      isWide={withContainer}
      as={withContainer ? Container : 'div'}
      isMain={isMainSlider}
      isSecondary={!isTertiary && isSecondarySlider}
      isTertiary={isTertiary}
      hasPaddingLeft
    >
      <Splide
        options={{
          ...defaultConfig,
          ...customConfig,
        }}
        onAutoplayPause={() => {
          if (pauseIconRef.current) {
            pauseIconRef.current.classList.add('is-visible');
          }
        }}
        onAutoplayPlay={() => {
          if (pauseIconRef.current) {
            pauseIconRef.current.classList.remove('is-visible');
          }
        }}
        renderControls={() => {
          return (
            hasMultipleSlides && (
              <PauseElement ref={pauseIconRef}>
                <PauseIcon />
              </PauseElement>
            )
          );
        }}
        onMounted={(data) => {
          const paginationData = data.Components.Pagination;
          const hasPaginationElements =
            hasMultipleSlides &&
            paginationData &&
            paginationData.items.length > 0;

          if (hasPaginationElements) {
            const { items } = paginationData;
            const { li: listDOMelement } = items[items.length - 1];

            pauseIconRef &&
              listDOMelement.parentElement?.appendChild(
                pauseIconRef.current as HTMLDivElement,
              );
          }
        }}
      >
        {slides.map((slide: any, i) => (
          <SplideSlide key={`slide-${i}`}>
            <SlideComponent
              {...(slide.slide
                ? { ...convertSlidePostToSlideProps(slide.slide) }
                : { ...omit(slide, ['slide']) })}
            />
          </SplideSlide>
        ))}
      </Splide>
      {isMainSlider && (
        <div className="hidden lg:block absolute bottom-0 z-20 -translate-x-66 -translate-y-12">
          <SliderPatternSVG />
        </div>
      )}
    </SplideOveriter>
  );
};

export default Slider;
