import Slider from '@components/views/Slider';
import { SlideProps, SplideOptions } from '@components/views/Slider/types';
import WysiwygWrapper from '@components/views/TitleDescriptionSection/WysiwygWrapper';
import Container from '@components/containers/Container';
import ButtonACF from '@components/containers/ButtonACF';
import styled from 'styled-components';
import ContainImage from '@components/views/ContainImage';
import Image from 'next/image';

interface TertiarySliderProps {
  slides: Array<SlideProps>;
}

const config: SplideOptions = {
  gap: 0,
};

const StyledWysiwygWrapper = styled(WysiwygWrapper)`
  h1 {
    margin-bottom: 0;
    font-size: clamp(2rem, 3.3vw, 4.8rem);
    line-height: 1.2;
  }

  h5 {
    margin-bottom: 0;
    font-size: clamp(1.4rem, 2.3vw, 3.2rem);
  }

  p {
    color: ${({ theme }) => theme.colors.neutral[9]};
    font-size: 2rem;
    font-weight: 800;
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 2rem;
    }

    h5 {
      font-size: 1.4rem;
    }
  }
`;

const TertiarySlider = ({ slides }: TertiarySliderProps) => {
  return (
    <div className="relative xxxl:max-w-[calc(var(--container-width)_+_18rem)] mx-auto">
      <Slider
        SlideComponent={(props) => {
          return (
            <section>
              <Container className="!absolute inset-0 pt-12 md:pt-24 lg:pt-64 pb-12 xl:pb-32">
                <div className="flex flex-col h-full justify-center">
                  {props.logo && (
                    <div className="self-start mb-12 mb-24 xl:mb-[clamp(2rem,5.8vw,8rem)] hidden md:block">
                      <Image
                        src={props.logo.url}
                        width={props.logo.width}
                        height={props.logo.height}
                        alt={props.logo.title}
                      />
                    </div>
                  )}
                  {props.title_description && (
                    <StyledWysiwygWrapper
                      dangerouslySetInnerHTML={{
                        __html: props.title_description,
                      }}
                    />
                  )}
                  {props.buttons && props.buttons.length > 0 && (
                    <div className="hidden sm:flex gap-6 mt-12 lg:mt-24 xl:mt-42">
                      {props.buttons.map(({ button }) => (
                        <ButtonACF key={button.title} {...button} />
                      ))}
                    </div>
                  )}
                  {props.extra_description && (
                    <StyledWysiwygWrapper
                      dangerouslySetInnerHTML={{
                        __html: props.extra_description,
                      }}
                      className="mt-auto text-right hidden md:block max-w-[59%]"
                    />
                  )}
                </div>
              </Container>
              {props.backgroundImage && (
                <ContainImage
                  alt=""
                  src={props.backgroundImage}
                  loading="eager"
                />
              )}
            </section>
          );
        }}
        slides={slides}
        config={config}
        isTertiary
      />
    </div>
  );
};

export default TertiarySlider;
