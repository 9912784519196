import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/image';
import { SlideProps } from '../../types';
import { mediaQueries } from '@styles/theme';

type BlockPosition = '1/2' | '2/3';

interface BlockProps {
  row: BlockPosition;
  col: BlockPosition;
  isTransparent?: boolean;
  className?: string;
}

const Block = styled.div<{
  row: BlockProps['row'];
  col: BlockProps['col'];
  isTransparent?: BlockProps['isTransparent'];
}>`
  width: 100%;
  height: 100%;
  z-index: 10;
  grid-row: ${({ row }) => row};
  grid-column: ${({ col }) => col};
  border: 1px solid
    ${({ isTransparent, theme }) =>
      isTransparent ? 'transparent' : theme.colors.neutral[0]};

  background: ${({ isTransparent, theme }) =>
    isTransparent ? 'transparent' : theme.colors.neutral[0]};
`;

const BlocksCollection: BlockProps[] = [
  { row: '1/2', col: '1/2' },
  { row: '2/3', col: '1/2', className: 'hidden lg:block' },
  {
    row: '2/3',
    col: '2/3',
    isTransparent: true,
    className: 'hidden lg:block',
  },
  { row: '1/2', col: '2/3', className: 'hidden lg:block' },
];

const BlockGrid = styled.div<{
  backgroundColor?: SlideProps['backgroundColor'];
}>`
  --width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, var(--tileWidth));
  grid-template-rows: repeat(2, var(--tileWidth));
  width: var(--width);
  --tileWidth: clamp(7rem, 20vw, 14rem);
  height: calc(var(--tileWidth) * 2);

  ${mediaQueries.greaterThan('md')`
    --tileWidth: 23.5rem;
    height: calc(var(--tileWidth) * 2);
  `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `}
`;

type DefaultSlidePatternProps = Pick<
  SlideProps,
  'backgroundColor' | 'backgroundImage'
>;

const DefaultSlidePattern: FC<DefaultSlidePatternProps> = ({
  backgroundColor,
  backgroundImage,
}: DefaultSlidePatternProps) => {
  return (
    <BlockGrid backgroundColor={backgroundColor}>
      {BlocksCollection.map((block, key) => (
        <Block {...block} key={key} />
      ))}
      {backgroundImage && (
        <Image
          src={backgroundImage}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt="obraz tła slajdu"
          style={{ zIndex: 8 }}
        />
      )}
    </BlockGrid>
  );
};

export default DefaultSlidePattern;
