import Slider from '@components/views/Slider';
import DefaultSlide from '@components/views/Slider/Slides/DefaultSlide';
import { SlideProps, SplideOptions } from '@components/views/Slider/types';

interface MainSliderProps {
  slides: Array<SlideProps>;
}

const config: SplideOptions = {};

const MainSlider = ({ slides }: MainSliderProps) => {
  return (
    <Slider
      SlideComponent={DefaultSlide}
      slides={slides}
      config={config}
      hasPaddingLeft
      withContainer
    />
  );
};

export default MainSlider;
